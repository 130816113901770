/* eslint-disable */
import {Vue} from 'vue-class-component';
import { Action, Getter, Mutation, State } from 'vuex-class'
import { CustomerState } from '@/store/customer/types';
import { clearSessionStorageService } from '@/services/storage.service'
const namespace: string = 'customer';


export default class UserAccountFavouritesPage extends Vue {
	@State('customer') customer: CustomerState | undefined;
	@Action('fetchUserData', {namespace}) fetchUserData: any;
	@Action('fetchFavorites', {namespace}) fetchFavorites: any;
	@Mutation('setIsLogin', {namespace}) setIsLogin: any;
	@Getter('getLogin', {namespace}) getLogin: any;

	logout() {
		clearSessionStorageService();
		this.setIsLogin(false);
		this.$router.push({ name: 'login' }).then(r  => r);
	}

	mounted() {
		this.fetchUserData();
		this.fetchFavorites();
	}
}
